import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useState } from 'react'

interface Params {
  [key: string]: string | number | undefined
}

const serializeObj = (obj: Params) => JSON.stringify(Object.values(obj))

export const useUpdateSearchParams = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const { replace } = useRouter()

  const [prevParams, setPrevParams] = useState<Params>({})

  const updateSearch = useCallback(
    (newParams: Params) => {
      if (serializeObj(newParams) !== serializeObj(prevParams)) {
        const params = new URLSearchParams(searchParams.toString())

        Object.keys(newParams).forEach((key) => {
          const value = newParams[key]
          if (value !== undefined) {
            params.set(key, value.toString())
          } else {
            params.delete(key)
          }
        })

        setPrevParams(newParams)
        replace(`${pathname}?${params.toString()}`)
      }
    },
    [prevParams, searchParams, pathname, replace]
  )

  return {
    updateSearch
  }
}
