import React, { forwardRef, Fragment } from 'react'
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList
} from '@chakra-ui/menu'
import { Box, Button, HStack, Icon, Text } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

interface ToolboxMenuItemProps extends MenuButtonProps {
  icon?: any
  label: string
  groups: ToolboxMenuItemGroup[]
  isDisabled?: boolean
}

export type ToolboxMenuItem = {
  id: string
  label: string
  action: () => void
  isActive: boolean
}

export type ToolboxMenuItemGroup = {
  id: string
  title: string
  items: ToolboxMenuItem[]
}

const ToolboxMenuItem = forwardRef(
  ({ isDisabled, groups, icon, ...props }: ToolboxMenuItemProps, ref) => {
    return (
      <Box
        sx={{
          '.chakra-menu__menu-list': {
            bg: 'background.default'
          }
        }}>
        <Menu>
          <MenuButton {...{ ref, isDisabled }} as={Button} {...props}>
            <Icon as={icon} />
          </MenuButton>

          <MenuList zIndex="popover" bg="background.default">
            {groups.map((group, index) => (
              <Fragment key={group.id}>
                <MenuGroup bg="background.default" title={group.title}>
                  {group.items.map((item) => (
                    <MenuItem key={item.id} onClick={item.action} bg="background.default">
                      <HStack w="full">
                        <CheckIcon
                          color={item.isActive ? 'text.default' : 'background.default'}
                          w={2}
                          h={2}
                        />
                        <Text>{item.label}</Text>
                      </HStack>
                    </MenuItem>
                  ))}
                </MenuGroup>
                {index === groups.length - 1 ? null : <MenuDivider />}
              </Fragment>
            ))}
          </MenuList>
        </Menu>
      </Box>
    )
  }
)

ToolboxMenuItem.displayName = 'ToolboxMenuItem'

export default ToolboxMenuItem
