import * as React from 'react'
import { Radio, RadioGroup, RadioGroupProps, Stack, useRadioGroup } from '@chakra-ui/react'

interface RadiusSelectorProps<T> extends Partial<Omit<RadioGroupProps, 'onToggle'>> {
  enum: { [key in keyof T]: string | number }
  onOptionSelection: (value: keyof T | null) => void
  formatOption?: (key: keyof T, value: string) => string
  value?: string
  onToggle?: (isOpen: boolean) => void
}

export const RadiusSelector = <T,>({
  enum: enumObj,
  onOptionSelection,
  formatOption,
  value = '',
  onToggle,
  ...props
}: RadiusSelectorProps<T>) => {
  const handleOptionSelection = (nextValue: string) => {
    const selectedValue = nextValue !== '' ? (nextValue as keyof T) : null
    onOptionSelection(selectedValue)
  }

  const format = (key: keyof T, value: string) => {
    if (formatOption) return formatOption(key, value)
    return value
  }

  const handleFocus = () => {
    if (onToggle) {
      onToggle(true)
    }
  }

  const handleBlur = () => {
    if (onToggle) {
      onToggle(false)
    }
  }

  const { getRadioProps } = useRadioGroup({
    name: 'radius-selector',
    value: value || '',
    onChange: handleOptionSelection
  })

  return (
    <RadioGroup
      value={value}
      onChange={handleOptionSelection}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}>
      <Stack gap={4} direction="column">
        {Object.entries(enumObj).map(([key, optionLabel]) => {
          const radio = getRadioProps({ value: key })
          return (
            <Radio key={key} {...radio}>
              {format(key as keyof T, optionLabel as string)}
            </Radio>
          )
        })}
      </Stack>
    </RadioGroup>
  )
}
