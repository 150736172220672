import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import CheckboxOptions, { CheckboxOption } from '@/app/components/common/CheckboxOptions'

interface CheckboxSelectorProps<T> extends Partial<Omit<BoxProps, 'onToggle'>> {
  options: CheckboxOption<T>[]
  selected: string[]
  handleStringCheckBoxes: (event: React.ChangeEvent<HTMLInputElement>) => void
  onToggle?: (isOpen: boolean) => void
  disableAll?: boolean
}

const CheckboxSelector = <T,>({
  options,
  selected,
  handleStringCheckBoxes,
  onToggle,
  disableAll,
  ...props
}: CheckboxSelectorProps<T>) => {
  const handleFocus = () => {
    if (onToggle) onToggle(true)
  }

  const handleBlur = () => {
    if (onToggle) onToggle(false)
  }

  return (
    <Box onFocus={handleFocus} onBlur={handleBlur} {...props}>
      <CheckboxOptions<T>
        disableAll={disableAll}
        options={options}
        selected={selected}
        onSelect={handleStringCheckBoxes}
      />
    </Box>
  )
}

export default CheckboxSelector
